import React, { useEffect, useState } from "react"

export const myContext = React.createContext()

const Provider = ({ children }) => {
  const [activeSpanishPage, setActiveSpanishPage] = useState(false)
  const [headerHeight, setHeaderHeight] = useState()
  const [isMobile, setIsMobile] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [navVisible, setNavVisible] = useState(true)
  const [activeParent, setActiveParent] = useState("")
  const [disableHeader, setDisableHeader] = useState(false)
  const [stickyView, setStickyView] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false)
  // isi State Management
  const [indicationOpen, setIndicationOpen] = useState(true)
  const [safetyOpen, setSafetyOpen] = useState(false)
  const [isiExpanded, setIsiExpanded] = useState(false)
  const [isISIInitial, setIsISIInitial] = useState(true)
  const [indicationHeight, setIndicationHeight] = useState()
  const rxDefineId = process.env.GATSBY_RXDEFINEID

  return (
    <myContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        isMobile,
        setIsMobile,
        mobileMenu,
        setMobileMenu,
        navVisible,
        setNavVisible,
        activeParent,
        setActiveParent,
        firstLoad,
        setFirstLoad,
        disableHeader,
        setDisableHeader,
        stickyView,
        setStickyView,
        indicationOpen,
        setIndicationOpen,
        safetyOpen,
        setSafetyOpen,
        isiExpanded,
        setIsiExpanded,
        isISIInitial,
        setIsISIInitial,
        indicationHeight,
        setIndicationHeight,
        rxDefineId,
        activeSpanishPage,
        setActiveSpanishPage,
      }}
    >
      {children}
    </myContext.Provider>
  )
}

const Consumer = ({ element }) => <Provider>{element}</Provider>
export default Consumer
