exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auvelity-on-my-side-js": () => import("./../../../src/pages/auvelity-on-my-side.js" /* webpackChunkName: "component---src-pages-auvelity-on-my-side-js" */),
  "component---src-pages-connect-with-a-provider-js": () => import("./../../../src/pages/connect-with-a-provider.js" /* webpackChunkName: "component---src-pages-connect-with-a-provider-js" */),
  "component---src-pages-how-auvelity-may-help-js": () => import("./../../../src/pages/how-auvelity-may-help.js" /* webpackChunkName: "component---src-pages-how-auvelity-may-help-js" */),
  "component---src-pages-how-auvelity-works-js": () => import("./../../../src/pages/how-auvelity-works.js" /* webpackChunkName: "component---src-pages-how-auvelity-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-savings-card-js": () => import("./../../../src/pages/savings-card.js" /* webpackChunkName: "component---src-pages-savings-card-js" */),
  "component---src-pages-savings-card-terms-js": () => import("./../../../src/pages/savings-card-terms.js" /* webpackChunkName: "component---src-pages-savings-card-terms-js" */),
  "component---src-pages-side-effects-js": () => import("./../../../src/pages/side-effects.js" /* webpackChunkName: "component---src-pages-side-effects-js" */),
  "component---src-pages-taking-auvelity-js": () => import("./../../../src/pages/taking-auvelity.js" /* webpackChunkName: "component---src-pages-taking-auvelity-js" */),
  "component---src-pages-ui-kit-js": () => import("./../../../src/pages/ui-kit.js" /* webpackChunkName: "component---src-pages-ui-kit-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

